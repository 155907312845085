
.anchor-offset {
	display: block;
	position: relative;
	top: -120px;
	visibility: hidden;
}

.aakuku-red {
	color: $aakuku-red;
}

.aakuku-yellow {
	color: $aakuku-yellow;
}

.aakuku-blue {
	color: $aakuku-blue;
}

.bg-aakuku-red {
	background-color: $aakuku-red;
}

.bg-aakuku-yellow {
	background-color: $aakuku-yellow;
}

.bg-aakuku-blue {
	background-color: $aakuku-blue;
}

.bg-gradient-subpage-title {
	background: $bg-gradient-subpage-title;
}

.bg-gradient-light-yellow {
	background: $bg-gradient-light-yellow;
}

.navbar-expand-lg {

	&.navbar {
		padding-top: 8px;
		padding-bottom: 8px;
	}
}

main {
	padding-top: 70px;
}

footer .nav .nav-link:hover {
	text-decoration: underline;
}


/* XS SM MD LG XL < XXL */
@media (max-width: 1399.98px) {
}


/* XS SM MD LG < XL */
@media (max-width: 1199.98px) {

}


/* XS SM MD < LG */
@media (max-width: 991.98px) {

}


/* XS SM < MD */
@media (max-width: 767.98px) {

}


/* XS < SM (XS only) */
@media (max-width: 575.98px) {
}


/* XXL XL LG MD SM > XS */
@media (min-width: 576px) {
}


/* XXL XL LG MD > SM */
@media (min-width: 768px) {
}


/* XXL XL LG > MD */
@media (min-width: 992px) {
	main {
		padding-top: 90px;
	}
}


/* XXL XL > LG */
@media (min-width: 1200px) {
}


/* XXL > XL (XXL only) */
@media (min-width: 1400px) {
}


/* SM only */
@media (min-width: 576px) and (max-width: 767.98px) {
}


/* MD only */
@media (min-width: 768px) and (max-width: 991.98px) {
}


/* LG only */
@media (min-width: 992px) and (max-width: 1199.98px) {
}


/* XL only */
@media (min-width: 1200px) and (max-width: 1399.98px) {
}